@import '../../styles/colors';
@import '../../styles/screens';
@import '../../styles/utils';
@import '../../styles/dimensions';
@import '../../styles/font-sizes';

$overlay-color: theme-var('modal-overlay');

.dialog {
    background: theme-var('card-background');
    color: theme-var('text');
    max-width: 90vw;
    min-width: none;
    outline: none;
    z-index: var(--snackbar-z-index);
    @include flex-center;
}

.small {
    max-width: $modal-width-small;
    text-align: center;
}

.medium {
    max-width: $modal-width-medium;
}

.container {
    outline: none;
    position: relative;
}

.close {
    bottom: 100%;
    color: theme-var('text');
    height: $icon-size-small;
    position: absolute;
    width: $icon-size-small;
}

.x {
    height: $icon-size-small;
    width: $icon-size-small;
}

.heading {
    margin-bottom: $gap-small;
    text-align: center;
}

.body {
    @include font-size-default;
    margin: 0 0 $gap-small;
    padding: 0;
    position: relative;
    white-space: pre-wrap;
}

.footer {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

@supports (display: grid) {
    .footer {
        display: grid;
        grid-gap: $gap-x-small;
        grid-template-columns: repeat(2, 1fr);
        justify-items: center;
        width: 100%;
    }

    .primary:only-child {
        grid-column: 1 / 3;
    }

    .small .primary:only-child {
        max-width: 100%;
    }
}

@supports not (display: grid) {
    .footer {
        display: flex;
        flex-direction: row;
        justify-content: center;
    }

    .primary,
    .secondary {
        flex: 0 0 40%;
    }

    .secondary {
        margin-right: $gap-x-small;
    }
}

.overlay {
    background: $overlay-color;
    inset: 0;
    pointer-events: all;
    position: absolute;
    @include flex-center;
    @include absolute-center;
}

@include screen-min-width('medium') {
    .dialog {
        padding: $modal-padding-desktop;
    }

    .close {
        left: 100%;
        margin: $gap-small;
    }

    .close:hover,
    .close:focus {
        @include hoverme;
    }
}

@include screen-max-width('medium') {
    .dialog {
        padding: $modal-padding-mobile;
    }

    .container {
        padding: 0 $gap-medium;
    }

    .close {
        margin: 0 0 $gap-small 0;
        right: $icon-size-small;
    }
}

@include screen-max-width('modal-button-layout-shift') {
    .footer {
        display: flex;
        flex-direction: column;
        gap: 0;
        width: 100%;
    }

    .primary,
    .secondary {
        width: 100%;
    }

    .secondary {
        margin-bottom: $gap-x-small;
    }
}
