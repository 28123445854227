@import '../../styles/utils';
@import '../../styles/dimensions';

.portal {
    bottom: 0;
    pointer-events: none;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: var(--portal-z-index);
}

.body {
    display: flex;
    flex-direction: column;
    height: var(--content-height);
    overflow: auto;
    pointer-events: all;
    width: 100%;
}
